<template>
  <b-sidebar
    id="add-new-bank-sidebar"
    :visible="isAddNewBankSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-bank-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("message.banks.newBankAccount") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewbank())"
          @reset.prevent="resetForm"
        >
          <!-- title -->
          <validation-provider
            #default="validationContext"
            name="titleName"
            rules="required"
          >
            <b-form-group
              label="titleName"
              label-for="titleName"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.banks.name") }}
              </template>
              <b-form-input
                id="titleName"
                v-model="bankData.name"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="bankName"
            rules="required"
          >
            <b-form-group
              label="bankName"
              label-for="bankName"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.banks.bankName") }}
              </template>
              <b-form-input
                id="bankName"
                v-model="bankData.bankName"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="bankNumber"
            rules="required"
          >
            <b-form-group
              label="bankNumber"
              label-for="bankNumber"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.banks.bankNumber") }}
              </template>
              <b-form-input
                type="number"
                id="bankNumber"
                v-model="bankData.bankNumber"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="agency"
            rules="required"
          >
            <b-form-group
              label="agency"
              label-for="agency"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.banks.bankAgency") }}
              </template>
              <b-form-input
                type="number"
                id="agency"
                v-model="bankData.bankAgency"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label="name"
              label-for="title"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.banks.bankAccount") }}
              </template>
              <b-form-input
                type="number"
                id="title"
                v-model="bankData.bankAccount"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import date from "@/@core/utils/date";
import dateInput from "@/@core/utils/dateInput";
import store from "@/store";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  model: {
    prop: "isAddNewBankSidebarActive",
    event: "update:is-add-new-bank-sidebar-active",
  },
  props: {
    isAddNewBankSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      activeDate: false,
      activeDay: false,
      flatConfig: {
        dateFormat: "d/m/Y",
      },
      flatConfigDay: {
        dateFormat: "j",
      },

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,

      disabledButton: false,
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInput(newVal);
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInput(this.$i18n.locale);
    }
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },

    submitNewbank() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.disabledButton = true;
          this.onSubmit();

          this.bankData.value = "0";

          axios({
            method: "post",
            url: `${URL_API}bank`,
            headers: getHeader(this.userData),
            data: this.bankData,
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("message.toast.create"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: `Banco criado com sucesso`,
                },
              });
            })
            .then(() => {
              this.$root.$emit("newListBank", null);
              this.disabledButton = false;
            })
            .catch((error) => {
              this.disabledButton = false;
              console.log(error);
            });
        }
      });
    },

    selectedType() {
      if (this.bankData.type === "isolate") {
        this.activeDate = true;
        this.activeDay = false;
      } else {
        this.activeDate = false;
        this.activeDay = true;
      }
    },
  },

  setup(props, { emit }) {
    const blankbankData = {};

    const bankData = ref(JSON.parse(JSON.stringify(blankbankData)));
    const resetbankData = () => {
      bankData.value = JSON.parse(JSON.stringify(blankbankData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-bank-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetbankData);

    return {
      bankData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-bank-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
